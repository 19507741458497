import React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/scss/styles.scss";

import PageNotFound from "./components/page-not-found/PageNotFound";
import Loader from "./components/loader/Loader";

import { createTheme, useMediaQuery, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Subscription from "./components/survey/Subscription";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { setOs, setDarkModePreference } from "./actions/appActions";

const App = (props: any) => {
  // console.log({ props });
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",

          background: {
            default: prefersDarkMode ? "#0A1929" : "#ffffff",
          },
          primary: {
            main: prefersDarkMode ? "#0A1929" : "#2980B9",
          },
          secondary: {
            main: prefersDarkMode ? "#031E3C" : "#42a5f5",
          },
          info: {
            main: prefersDarkMode ? "#42A5F5" : "#0288d1",
          },
        },
      }),
    [prefersDarkMode]
  );

  const checkAllowConsoleLogs = () => {
    if (
      !process.env?.REACT_APP_ALLOW_CONSOLE_LOGS ||
      process.env?.REACT_APP_ALLOW_CONSOLE_LOGS === "false"
    ) {
      if (window) {
        window.console.table =
          window.console.log =
          window.console.warn =
          window.console.info =
          window.console.dir =
            function () {
              // Don't log or dispatch anything.
            };
      }
    }
  };

  React.useEffect(() => {
    checkAllowConsoleLogs();
    props.setOs();
    props.setDarkModePreference(prefersDarkMode);

    console.log("App : useEffect", { props });
    //eslint-disable-next-line
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="app-container">
        <Routes>
          <Route path="/surveys">
            <Route
              path=":surveyId/preview/:previewId"
              element={<SurveySuspense />}
            />
            <Route path=":surveyInstanceId" element={<SurveySuspense />} />
          </Route>

          <Route path="/adHocSurveys">
            <Route path=":orgCode/:adHocCode" element={<SurveySuspense />} />
          </Route>

          <Route path="/unsubscribe/:custId" element={<Subscription />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

App.propTypes = {
  app: PropTypes.object.isRequired,
  setOs: PropTypes.func.isRequired,
  setDarkModePreference: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps, {
  setOs,
  setDarkModePreference,
})(App);

export const SurveySuspense = (props: any) => {
  const Survey = React.lazy(() => import("./components/survey/Survey"));
  return (
    <React.Suspense fallback={<Loader />}>
      <Survey />
    </React.Suspense>
  );
};
