import { UserResponseStruct } from "./survey/survey-response.model";
import {
  Survey,
  SurveyQuestion,
  SurveyQuestionRow,
  UiConfigOptions,
} from "./survey/survey.model";

export enum SurveyDisplayMode {
  preview = "preview",
  campaignSurvey = "campaignSurvey",
  adHocSurvey = "adHocSurvey",
  bad404 = "bad404",
}

export interface PostSurveyPageProps {
  showPostSurveyPage: boolean;
  postSurveyPageMessage: string | null;
  hidePoweredByCentriqe: boolean;
  isCentriqeLogo: boolean;
}

export interface SurveyStateProps extends PostSurveyPageProps {
  surveyDisplayMode: SurveyDisplayMode | undefined;
  logoUrl: string | null;
  orgName: string | null;

  survey: Survey | null;
  showOrgLogoInNavbar: boolean;
  totalPagesArrayLen: number;

  surveyInstanceId: string | null;
  adHocParams: {
    orgCode: string;
    adHocCode: string;
  } | null;

  isLoading: boolean;
}

export enum SurveyActionType {
  SET_DISPLAY_MODE = "SET_DISPLAY_MODE",
  SET_DEFAULTS_AFTER_FAILED_SURVEY_API = "SET_DEFAULTS_AFTER_FAILED_SURVEY_API",
  SET_SURVEY_INSTANCE_FROM_API = "SET_SURVEY_INSTANCE_FROM_API",
  SET_LOADING = "SET_LOADING",
  SET_DEFAULTS_FOR_NO_SURVEY_ALLOWED = "SET_DEFAULTS_FOR_NO_SURVEY_ALLOWED",
  SET_POST_SURVEY_PAGE_PROPS = "SET_POST_SURVEY_PAGE_PROPS",
  SET_SURVEY_INSTANCE_ID = "SET_SURVEY_INSTANCE_ID",
}

export interface AdHocSurveyUserData {
  cellPhone: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface SurveyFormStateProps {
  isSurveySubmitted: boolean;
  isSurveySubmissionError: boolean;

  showAdhocAddlInputPage: boolean;
  adHocSurveyInstance: any;
  adHocSurveyUserData: AdHocSurveyUserData | null;

  currentSurveyPage: number;
  isLoading: boolean;
  isPendingRequiredResponses: boolean;
  highlightRequiredFields: boolean;
}

export enum SurveyFormStateActionType {
  SET_CURRENT_SURVEY_PAGE = "SET_CURRENT_SURVEY_PAGE",
  SET_SURVEY_SUBMISSION_FROM_API = "SET_SURVEY_SUBMISSION_FROM_API",
  SET_ADHOC_SURVEY_USERDATA = "SET_ADHOC_SURVEY_USERDATA",
  SET_LOADING = "SET_LOADING",
  SET_PENDING_RESPONSES = "SET_PENDING_RESPONSES",
  CLEAR_PENDING_RESPONSES = "CLEAR_PENDING_RESPONSES",
  CLEAR_HIGHLIGHT_REQUIRED_FIELDS = "CLEAR_HIGHLIGHT_REQUIRED_FIELDS",
}

export interface SurveyResponseStateProps {
  responses: UserResponseStruct[];
}

export enum SurveyResponseStateActionType {
  INIT_RESPONSES_FROM_CAMPAIGN_SURVEY = "INIT_RESPONSES_FROM_CAMPAIGN_SURVEY",
  ADD_USER_RESPONSE = "ADD_USER_RESPONSE",
  REMOVE_USER_RESPONSE = "REMOVE_USER_RESPONSE",

  ADD_USER_RESPONSE_ADDL_TEXT = "ADD_USER_RESPONSE_ADDL_TEXT",
  REMOVE_USER_RESPONSE_ADDL_TEXT = "REMOVE_USER_RESPONSE_ADDL_TEXT",
}

export interface LastSelectedResponse {
  selectedValue: string | any;
  selectedAnalyticsValue: any;
  additionalText: string;
}

export interface SelectionChangedProps {
  newValue: any;
  lastSelection: string | null | any;
  selectedValue?: string | null | any;
}

export interface BaseQuestionFormProps {
  question: SurveyQuestion;
  responses: UserResponseStruct[];
}

export interface QuestionFormProps extends BaseQuestionFormProps {
  selectedValue: string | null | any;
  selectedAnalyticsValue: any;
  lastSelection: string | null | any;
  additionalText: string;
  onSelectionChanged: (selectionChangedProps: SelectionChangedProps) => void;
  onChangeAdditionalText: (changedText: string) => void;
}

export interface AdditionalTextProps {
  showAdditionalTextField: boolean;
  uiConfig: UiConfigOptions;
  onChangeText: (changedText: string) => void;
  required?: boolean;
  additionalText: string;
}

export interface LikertRowProps extends BaseQuestionFormProps {
  row: SurveyQuestionRow;
  accordionExpanded: string | boolean;
  handleAccordionChange: (panel: string) => void;
}
