import { AppActionType } from "../models/app.model";

export const setOs = () => (dispatch: any) => {
  let isApple = false;

  const userAgentData = (navigator as any)?.userAgentData;

  if (
    !!userAgentData &&
    !!userAgentData?.platform &&
    userAgentData?.platform !== ""
  ) {
    isApple = userAgentData.platform.includes("mac");
  } else {
    isApple =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator?.platform) ||
      // iPad on iOS 13 detection
      navigator?.userAgent?.includes("Mac");
  }

  console.log("AppState : setOs", { isApple });

  dispatch({ type: AppActionType.SET_OS_TYPE, payload: isApple });
};

export const setDarkModePreference =
  (prefersDarkModeProp: boolean) => (dispatch: any) =>
    dispatch({
      type: AppActionType.SET_DARK_MODE_PREFERENCE,
      payload: prefersDarkModeProp,
    });
