import axios from "axios";
import { kBackendUrl } from "../models/app.model";
import { SubscriptionActionType } from "../models/subscription.model";

export const fetchSubscription =
  (custId: string) => async (dispatch: any, currentState: any) => {
    try {
      const response = await axios.get(
        `${kBackendUrl}/cust/unsubscribe/${custId}`
      );

      console.log("fetchSubscription API response", { response });

      dispatch({
        type: SubscriptionActionType.FETCH_SUBSCRIPTION_STATUS,
        payload: response,
      });
    } catch (error: any) {
      console.log("fetchSubscription API error", { error });

      const apiErrorMessage =
        error?.response?.status === 500
          ? `There was an error getting your subscription status from the server, probably due to
      scheduled server maintenance. Your association is valuable to us. Please try refreshing the browser after some time. If issue persists, we
      request you to contact our helpdesk`
          : `There was an error getting your subscription status from the server, probably due to
      network congestion. Your association is valuable to us. Please try refreshing the browser after some time. If issue persists, we
      request you to contact our helpdesk - status: ${error?.response?.status}, message: ${error?.response?.statusText}`;

      dispatch({
        type: SubscriptionActionType.ON_FETCH_API_ERROR,
        payload: { apiErrorMessage },
      });
    }
  };

export const updateSubscription =
  (custId: string) => async (dispatch: any, currentState: any) => {
    try {
      const response = await axios.put(
        `${kBackendUrl}/cust/unsubscribe/${custId}`
      );

      console.log("updateSubscription API response", { response });

      dispatch({
        type: SubscriptionActionType.UPDATE_SUBSCRIPTION_STATUS,
        payload: response,
      });
    } catch (error: any) {
      console.log("updateSubscription API error", { error });

      const apiErrorMessage = `There was an error updating your subscription status on the server, probably due to
      network congestion. Your association is valuable to us. Please try again after some time. If issue persists, we
      request you to contact our helpdesk quoting the following - status: ${error?.response?.status}, message: ${error?.response?.statusText}`;

      dispatch({
        type: SubscriptionActionType.ON_UPDATE_API_ERROR,
        payload: { apiErrorMessage },
      });
    }
  };

export const setLoading = () => ({ type: SubscriptionActionType.SET_LOADING });
