interface OrgData {
  code: string;
  logoUrl: string;
  name: string;
}

export interface SubscriptionStateProps {
  isInitialising: boolean;
  apiErrorMessage: string | null;
  orgData: OrgData | null;
  isUnsubscribed: boolean;
  doNotDismiss: boolean;
  apiError: boolean;
  isLoading: boolean;
}

export enum SubscriptionActionType {
  FETCH_SUBSCRIPTION_STATUS = "FETCH_SUBSCRIPTION_STATUS",
  UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS",
  ON_FETCH_API_ERROR = "ON_FETCH_API_ERROR",
  ON_UPDATE_API_ERROR = "ON_UPDATE_API_ERROR",
  SET_LOADING = "SET_LOADING",
}
