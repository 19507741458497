import { ReducerActionProps } from "../models/app.model";
import {
  SurveyResponseStateActionType,
  SurveyResponseStateProps,
} from "../models/survey-redux.model";
import { UserResponseStruct } from "../models/survey/survey-response.model";
import { SurveyQuestionTypeCode } from "../models/survey/survey.enums";

const initialState = {
  responses: [],
} as SurveyResponseStateProps;

const getResponseIndex = (
  state: SurveyResponseStateProps,
  action: ReducerActionProps
) => {
  const responses =
    state.responses.map((r: UserResponseStruct) => r) ??
    ([] as UserResponseStruct[]);

  const questionType = action?.payload?.questionType;

  const searchFunc =
    !!action?.payload?.lastUserSelection?.surveyValueId &&
    !!action?.payload?.lastUserSelection?.surveyQuestionRowId
      ? (r: UserResponseStruct) =>
          r.surveyQuestionId ===
            action?.payload?.lastUserSelection?.surveyQuestionId &&
          r.surveyValueId ===
            action?.payload?.lastUserSelection?.surveyValueId &&
          r.surveyQuestionRowId ===
            action?.payload?.lastUserSelection?.surveyQuestionRowId
      : !!action?.payload?.lastUserSelection?.surveyQuestionRowId
      ? (r: UserResponseStruct) =>
          r.surveyQuestionId ===
            action?.payload?.lastUserSelection?.surveyQuestionId &&
          r.surveyQuestionRowId ===
            action?.payload?.lastUserSelection?.surveyQuestionRowId
      : !!action?.payload?.lastUserSelection?.surveyValueId
      ? (r: UserResponseStruct) =>
          r.surveyQuestionId ===
            action?.payload?.lastUserSelection?.surveyQuestionId &&
          r.surveyValueId === action?.payload?.lastUserSelection?.surveyValueId
      : (r: UserResponseStruct) =>
          r.surveyQuestionId ===
          action?.payload?.lastUserSelection?.surveyQuestionId;

  let foundIndex = -1;

  if (
    (action.type === SurveyResponseStateActionType.ADD_USER_RESPONSE ||
      action.type === SurveyResponseStateActionType.REMOVE_USER_RESPONSE) &&
    !!action?.payload?.lastUserSelection
  ) {
    let userResponseSearchFunc = searchFunc;

    switch (questionType) {
      case SurveyQuestionTypeCode.MultiChoiceCheckHorizontal:
      case SurveyQuestionTypeCode.LikertCheck:
        userResponseSearchFunc = (r: UserResponseStruct) =>
          r.surveyQuestionId ===
            action?.payload?.lastUserSelection?.surveyQuestionId &&
          r.surveyQuestionRowId ===
            action?.payload?.lastUserSelection?.surveyQuestionRowId &&
          r.surveyValueId ===
            action?.payload?.lastUserSelection?.surveyValueId &&
          !r?.text;
        break;
    }

    foundIndex = responses.findIndex(userResponseSearchFunc);

    // console.log("SurveyReducer", {
    //   "action.type": action.type,
    //   payload: action.payload,
    //   foundIndex,
    // });
  }

  if (
    action.type === SurveyResponseStateActionType.ADD_USER_RESPONSE_ADDL_TEXT ||
    action.type === SurveyResponseStateActionType.REMOVE_USER_RESPONSE_ADDL_TEXT
  ) {
    let addlUserSearchFunc = searchFunc;

    switch (questionType) {
      case SurveyQuestionTypeCode.MultiChoiceCheckHorizontal:
      case SurveyQuestionTypeCode.LikertCheck:
        addlUserSearchFunc = (r: UserResponseStruct) =>
          r.surveyQuestionId ===
            action?.payload?.lastUserSelection?.surveyQuestionId &&
          r.surveyQuestionRowId ===
            action?.payload?.lastUserSelection?.surveyQuestionRowId &&
          !!r?.text &&
          !r?.surveyValueId;
        break;
    }

    foundIndex = responses.findIndex(addlUserSearchFunc);
    // console.log("SurveyReducer", {
    //   "action.type": action.type,
    //   payload: action.payload,
    //   foundIndex,
    //   searchFunc: searchFunc.toString(),
    // });
  }

  return foundIndex;
};

const SurveyResponseReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  const responses =
    state.responses.map((r: UserResponseStruct) => r) ??
    ([] as UserResponseStruct[]);

  const questionType = action?.payload?.questionType;
  const foundIndex = getResponseIndex(state, action);

  switch (action.type) {
    case SurveyResponseStateActionType.INIT_RESPONSES_FROM_CAMPAIGN_SURVEY:
      return {
        ...state,
        responses: action.payload,
      };

    /** User Responses */
    case SurveyResponseStateActionType.ADD_USER_RESPONSE:
      switch (questionType) {
        case SurveyQuestionTypeCode.MultiChoiceCheckHorizontal:
        case SurveyQuestionTypeCode.MatrixCheck:
        case SurveyQuestionTypeCode.LikertCheck:
          responses.push(action.payload.userSelection);
          break;

        default:
          foundIndex > -1
            ? (responses[foundIndex] = action.payload.userSelection)
            : responses.push(action.payload.userSelection);
      }

      // console.log("SurveyResponseStateActionType.ADD_USER_RESPONSE", {
      //   foundIndex,
      //   responses,
      //   searchFunc: searchFunc.toString(),
      // });

      return {
        ...state,
        responses,
      };

    case SurveyResponseStateActionType.ADD_USER_RESPONSE_ADDL_TEXT:
      foundIndex > -1
        ? (responses[foundIndex] = action.payload.userSelection)
        : responses.push(action.payload.userSelection);

      return {
        ...state,
        responses,
      };

    case SurveyResponseStateActionType.REMOVE_USER_RESPONSE:
      foundIndex > -1 && responses.splice(foundIndex, 1);

      return {
        ...state,
        responses,
      };

    case SurveyResponseStateActionType.REMOVE_USER_RESPONSE_ADDL_TEXT:
      switch (questionType) {
        case SurveyQuestionTypeCode.MultiChoiceCheckHorizontal:
        case SurveyQuestionTypeCode.LikertCheck:
          foundIndex > -1 && responses.splice(foundIndex, 1);
          break;

        default:
          if (foundIndex > -1 && "text" in responses[foundIndex]) {
            delete responses[foundIndex].text;
          }
      }

      return {
        ...state,
        responses,
      };

    default:
      return state;
  }
};

export default SurveyResponseReducer;
