import React from "react";
import { useParams } from "react-router-dom";

import usePageResize from "../../hooks/usePageResize";

import NetworkAlerts from "../shared/util/NetworkAlerts";
import Stack from "@mui/material/Stack";
import Loader from "../loader/Loader";
import ColouredBox from "../shared/ColouredBox";
import ApiErrorUserAlert from "../shared/util/ApiErrorUserAlert";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { connect } from "react-redux";

import {
  fetchSubscription,
  updateSubscription,
  setLoading,
} from "../../actions/subscriptionActions";
import PropTypes from "prop-types";
import { SubscriptionStateProps } from "../../models/subscription.model";

const Subscription = ({
  subscription,
  fetchSubscription,
  updateSubscription,
  setLoading,
}: any) => {
  const {
    isInitialising,
    orgData,
    apiErrorMessage,
    doNotDismiss,
    isUnsubscribed,
    isLoading,
    apiError,
  } = subscription as SubscriptionStateProps;

  const { isMobileView } = usePageResize();
  const params = useParams();

  console.log("Subscription", { params });
  const custId = params["custId"];

  const [triggerChangeSubscription, setTriggerChangeSubscription] =
    React.useState(false);

  React.useEffect(() => {
    if (!!isInitialising) {
      fetchSubscription(custId);
    }

    if (!!triggerChangeSubscription) {
      setLoading();
      updateSubscription(custId);

      setTriggerChangeSubscription(false);
    }

    //eslint-disable-next-line
  }, [triggerChangeSubscription]);

  if (!!isInitialising) return <Loader />;

  return (
    <Container sx={{ marginTop: "5rem" }}>
      <NetworkAlerts />

      <Stack spacing={3} sx={{ textAlign: "center" }}>
        {!!orgData?.logoUrl && (
          <img
            className="logo logo-fixed-extra-width logo-contained"
            src={orgData?.logoUrl}
            alt={orgData?.name}
          />
        )}
        {!!orgData?.name && <h2>{orgData?.name}</h2>}
        <ColouredBox padding={isMobileView ? "20px" : "50px"}>
          {!!apiError && (
            <ApiErrorUserAlert
              errorMessage={apiErrorMessage}
              doNotDismiss={doNotDismiss}
            />
          )}

          {!!orgData && (
            <Stack spacing={3} sx={{ textAlign: "left" }}>
              <p style={{ fontSize: "1.5rem", fontWeight: 500 }}>
                Subscription status
              </p>
              {!!isLoading && <Loader />}
              {!isLoading && (
                <p style={{ fontSize: "1.25rem" }}>
                  You are currently{" "}
                  <span
                    style={{
                      padding: ".25rem",
                      backgroundColor: !!isUnsubscribed
                        ? "rgba(255, 0, 0, 0.2)"
                        : "rgba(51, 204, 51, 0.2)",
                      borderRadius: ".2rem",
                    }}
                  >
                    {!!isUnsubscribed ? "unsubscribed from" : "subscribed to"}
                  </span>{" "}
                  <b>our newsletters, surveys, and promotional</b> emails.
                </p>
              )}
              {!isLoading && (
                <Button
                  variant="contained"
                  color={!!isUnsubscribed ? "success" : "error"}
                  size="large"
                  sx={{
                    textTransform: "none",
                    fontSize: "1.25rem",
                    padding: "6px 12px",
                    lineHeight: 1.5,
                    width: isMobileView ? "auto" : "fit-content",
                  }}
                  onClick={() => setTriggerChangeSubscription(true)}
                >
                  {!!isUnsubscribed ? "Subscribe" : "Unsubscribe"}
                </Button>
              )}
            </Stack>
          )}
        </ColouredBox>
      </Stack>
    </Container>
  );
};

Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  fetchSubscription: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state: any) => ({
  subscription: state.subscription,
});

export default connect(mapStateToProps, {
  fetchSubscription,
  updateSubscription,
  setLoading,
})(Subscription);
