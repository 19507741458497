import React from "react";
import "./Loader.scss";
import { PuffLoader, FadeLoader } from "react-spinners";

import { connect } from "react-redux";
import PropTypes from "prop-types";

const Loader = ({ isApple, prefersDarkMode }: any) => {
  const colour = prefersDarkMode ? "#ffffff" : "#000000";

  return (
    <div className="spinner-container">
      {/* <div className="loading-arc">
        <div className="arc"></div>
        <div className="arc"></div>
        <div className="arc"></div>
      </div> */}
      {isApple ? <FadeLoader color={colour} /> : <PuffLoader color={colour} />}
    </div>
  );
};

Loader.propTypes = {
  isApple: PropTypes.bool.isRequired,
  prefersDarkMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state: any) => ({
  isApple: state.app.isApple,
  prefersDarkMode: state.app.prefersDarkMode,
});

export default connect(mapStateToProps)(Loader);
