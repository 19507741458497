export enum AlertActionType {
  SHOW_SAVED_DATA_ALERT = "SHOW_SAVED_DATA_ALERT",
  HIDE_SAVED_DATA_ALERT = "HIDE_SAVED_DATA_ALERT",

  SHOW_API_ERROR_ALERT = "SHOW_API_ERROR_ALERT",
  HIDE_API_ERROR_ALERT = "HIDE_API_ERROR_ALERT",

  SET_MAIN_LOADING_ON = "SET_MAIN_LOADING_ON",
  SET_MAIN_LOADING_OFF = "SET_MAIN_LOADING_OFF",
}

export interface AlertStateProps {
  showSavedDataAlert: boolean;
  showApiErrorAlert: boolean;
  isMainAppLoading: boolean;
}
